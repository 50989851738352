#footer {
  z-index: 3;
  #footer-content {
    width: 100%;
    text-align: center;
  }
  .footer-item {
    color: #ffffff;
  }
}
