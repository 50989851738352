#sector {
  margin-top: 60px;
  padding-bottom: 50px;
  #title {
    text-align: center;
    padding-bottom: 30px;
  }
  #subtitle {
    text-align: center;
    color: rgb(51, 164, 240);
    margin-bottom: -5px;
  }
  .sectorItem {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: 25px;
  }
  .itemImageContainer {
    width: 45%;
    height: 250px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inverseImageContainer {
    flex-direction: row-reverse;
  }
  .itemImage {
    object-fit: cover;
    width: 100%;
    height: 400px;
    border-radius: 50%;
  }
  .textContainer {
    width: 51%;
  }
}

@media (max-width: 766px) {
  #sector {
    .sectorItem,
    .inverseImageContainer {
      flex-direction: column;
      justify-content: center;
      gap: 30px;
    }
    .itemImageContainer,
    .textContainer {
      width: 100%;
    }
  }
}
